import React, { useContext } from "react";

import { IAlertProps } from "./implementations/AlertModal";
import { IConfirmProps } from "./implementations/ConfirmModal";
import { IErrorProps } from "./implementations/ErrorModal";
import { IPromptProps } from "./implementations/PromptModal";
import { ModalSize } from "./Modal";

export interface IOpenAlertProps extends Omit<IAlertProps, "onClose"> {
  content: React.ReactNode;
}
export interface IOpenErrorProps extends Omit<IErrorProps, "onClose"> {
  content: React.ReactNode;
}
export interface IOpenPromptProps extends Omit<IPromptProps, "onClose" | "onConfirm"> {
  content: React.ReactNode;
}
export interface IOpenConfirmProps extends Omit<IConfirmProps, "onClose" | "onConfirm" | "onCancel"> {
  content: React.ReactNode;
}

export interface IOpenModalProps {
  className?: string;
  title?: string;
  content: React.ReactNode;
  size?: ModalSize;
}

export interface IModalContext {
  closeModal: () => void;
  openModal: (props: IOpenModalProps) => Promise<undefined>;
  openAlert: (props: IOpenAlertProps) => Promise<undefined>;
  openError: (props: IOpenErrorProps) => Promise<undefined>;
  openPrompt: (props: IOpenPromptProps) => Promise<string | undefined>;
  openConfirm: (props: IOpenConfirmProps) => Promise<boolean | undefined>;
}

const ModalContext = React.createContext<IModalContext | undefined>(undefined);

export const ModalProvider = ModalContext.Provider;

/**
 * This example demonstrates how to use the `useModal` hook to open an alert modal.
 *
 * @example
 * ```
 * const modals = useModal();
 * const result = await modals.openAlertModal(props)
 * console.log(result) # true | false
 * ```
 */
export function useModal() {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal needs to have ModalManager as a parent");
  }
  return context;
}

export function withModal<T>(Component: React.ComponentType<T>) {
  return function BoundComponent(props: Pick<T, Exclude<keyof T, keyof IModalContext>>) {
    return (
      <ModalContext.Consumer>
        {(value) => {
          if (value === undefined) {
            throw new Error("withModal needs to have ModalManager as a parent");
          }
          return <Component {...(props as T)} {...value} />;
        }}
      </ModalContext.Consumer>
    );
  };
}
