import { z } from "zod";

import { undefinable } from "../../utils/zodHelpers";

export const EnergyLabelTypes = z.enum(["standard", "heating", "cooling"]);

export const EnergyLabelValidator = z.object({
  image: undefinable(z.string()),
  datafile: undefinable(z.string()),
  labels: z.array(
    z.object({
      label: undefinable(z.string()),
      scale: z.string(),
      type: EnergyLabelTypes,
    }),
  ),
});

export type IEnergyLabels = z.infer<typeof EnergyLabelValidator>;
