import { z } from "zod";

import { undefinable } from "../../shared/utils/zodHelpers";

export const OpeningTimeDayValidator = z.object({
  from_time: z.string(),
  to_time: z.string(),
});

export const OpeningTimeValidator = z.object({
  monday: undefinable(OpeningTimeDayValidator),
  tuesday: undefinable(OpeningTimeDayValidator),
  wednesday: undefinable(OpeningTimeDayValidator),
  thursday: undefinable(OpeningTimeDayValidator),
  friday: undefinable(OpeningTimeDayValidator),
  saturday: undefinable(OpeningTimeDayValidator),
  sunday: undefinable(OpeningTimeDayValidator),
});

export type IOpeningTime = z.infer<typeof OpeningTimeValidator>;
export type IOpeningHour = z.infer<typeof OpeningTimeDayValidator>;
