import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { usePress, usePreventScroll } from "react-aria";

import styles from "../Modal.style.scss";

interface IModalOverlayProps {
  onClose: () => void;
  classNames: string;
}

export const ModalOverlay: FunctionComponent<React.PropsWithChildren<IModalOverlayProps>> = ({
  children,
  onClose,
  classNames,
}) => {
  const { pressProps } = usePress({
    onPress: (e) => {
      onClose();
    },
  });

  usePreventScroll();

  return (
    <div className={clsx(styles.overlayContainer, classNames)}>
      <div role="close-outside" {...pressProps} className={styles.overlay} />
      {children}
    </div>
  );
};
