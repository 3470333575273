import { isHTMLElement } from "../../shared/utils/types/isHTMLElement";

(() => {
  document.querySelector(".js-breadcrumb-expander")?.addEventListener("click", (event) => {
    const target = event.target;
    if (!isHTMLElement(target)) {
      return;
    }
    const list = target.closest(".breadcrumb");
    if (!isHTMLElement(list)) {
      return;
    }
    list.classList.add("breadcrumb__expanded");
  });
})();
