import { z } from "zod";

import { undefinable } from "../../zodHelpers";
import { BaseProductEventValidator } from "./shared";

export const CheckoutEventValidator = z.object({
  /**
   * The current checkout step.
   *
   * These steps are:
   * 1. Cart (Basket)
   * 2. Personal Information (Adresse)
   * 3. Shipping (Vælg en leveringsmetode)
   * 4. Payment (Betalingsmetode)
   */
  step: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
  /**
   * Options for the current checkout step.
   *
   * The option are only used for some steps, see:
   * 1. *Not used*
   * 2. *Not used*
   * 2. Describes the shipping method, e.g. "Levering til adresse"
   * 2. Describes the payment method, e.g. "MobilePay"
   */
  option: undefinable(z.string()),
  products: z.array(
    BaseProductEventValidator.merge(
      z.object({
        /**
         * The quantity number of the product being added to the basket
         *
         * @example 2
         */
        quantity: z.number(),
        /**
         * If coupon is applied to the entire transaction, every product should contain this parameter with
         * the code used by the visitor. Otherwise, if coupon is added for a specific product, just use this
         * parameter on that specific product.
         *
         * @example "summersales22"
         */
        coupon: undefinable(z.string()),
      }),
    ),
  ),
});

export type ICheckoutEvent = z.infer<typeof CheckoutEventValidator>;
