import clsx from "clsx";
import React, { FunctionComponent, PropsWithChildren } from "react";

import styles from "./DrawerTitle.style.scss";

interface IDrawerTitleProps {
  className?: string;
}

const DrawerTitle: FunctionComponent<PropsWithChildren<IDrawerTitleProps>> = ({ children, className }) => {
  return <h2 className={clsx(styles.header, className)}>{children}</h2>;
};

export const _DrawerTitle = DrawerTitle;
