import Cookies from "js-cookie";

import { isHTMLElement } from "../../shared/utils/types/isHTMLElement";

(() => {
  const topbanners = document.querySelectorAll(".js-hide-topbanner");

  topbanners.forEach((topbanner) => {
    if (!isHTMLElement(topbanner)) {
      return;
    }

    topbanner.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      Cookies.set(`js-hide-topbanner`, "1");
      document.querySelectorAll(".topbanners")?.forEach((x) => x.remove());
      return false;
    });
  });
})();
