// eslint-disable-next-line no-restricted-imports -- Type imports does not increase bundle size
import type * as Sentry from "@sentry/browser";
import type { SetReturnType } from "type-fest";

// Based on https://github.com/Daniel15/LazySentry/blob/main/LazySentry.ts

type SentryType = typeof Sentry;

const sentryQueue: ((sentry: SentryType) => void)[] = [];
const exceptionQueue: Parameters<OnErrorEventHandlerNonNull>[] = [];
const rejectPromiseQueue: PromiseRejectionEvent[] = [];
let sentryLoaded = false;

/* eslint-disable unicorn/prefer-add-event-listener */
const originalOnError = global?.window?.onerror;
const originalUnhandledRejection = global?.window?.onunhandledrejection;

const loadSentry = async () => {
  if (sentryLoaded) {
    return;
  }
  sentryLoaded = true;

  const Sentry = await import("@sentry/browser");
  window.onerror = originalOnError;
  window.onunhandledrejection = originalUnhandledRejection;

  Sentry.init({
    integrations: [Sentry.dedupeIntegration(), Sentry.extraErrorDataIntegration()],
    allowUrls: [/ao-cdn\.net/],
    dsn: window.SENTRY_PUBLIC_DSN,
    attachStacktrace: true,
    release: "$REPLACE_WITH_RELEASE",
    ignoreErrors: [
      // Error generated by a bug in auto-fill library from browser
      // https://github.com/getsentry/sentry/issues/5267
      /Blocked a frame with origin/,
      "Network request failed",
      "NetworkError",
      "Failed to fetch",
      "UnknownError",
      "TypeError: Failed to fetch",
      "TypeError: Load failed",
      "TypeError: NetworkError when attempting to fetch resource",
      "TypeError: annulleret",
      "TypeError: anulowane",
      "TypeError: Internetforbindelsen synes at være offline",
      "TypeError: The internet connection appears to be offline",
      "TypeError: Webkit registrerede en intern fejl",
      "TypeError: The request timed out",
      "TypeError: Anmodningen fik timeout",
      "TypeError: Netværksforbindelsen gik tabt",
      "TypeError: Anmodningen udløb",
      "TypeError: Der er opstået en SSL-fejl, og der kan ikke oprettes en sikker forbindelse til serveren.",
      "TypeError: Der blev ikke fundet en server med det anførte værtsnavn.",
      "TypeError: Kunne ikke oprette forbindelse til serveren.",
      "The quota has been exceeded",
      "ResizeObserver loop limit exceeded" /* Benign error */,
      /ResizeObserver loop completed with undelivered notifications/ /* Benign error */,
      /The user denied permission to use Service Worker/ /* Browser notification permission denied */,
      /NS, campaignId, campaignName/ /* sleeknote */,
      /Loading CSS chunk/ /* Sporadic CSS load issues */,
      "Unreachable hosts - your application id may be incorrect. If the error persists, contact support@algolia.com." /* Sporadic algolia issue */,
      "Context is stopped", // ???
      "Cannot redefine property: googletag", // 3rd party scripts
      // Errors by unsupported browsers:
      "Can't find variable: IntersectionObserver",
      "Can't find variable: ResizeObserver",
      "fetch is not defined",
      /flatMap is not a function/,
    ],
    beforeSend(event) {
      if (/^(.*CloudFlare-AlwaysOnline.*|.+MSIE 8\.0;.+)$/.test(window.navigator.userAgent)) {
        return null;
      }
      return event;
    },
  });

  addBreadcrumb = Sentry.addBreadcrumb;
  captureMessage = Sentry.captureMessage;
  captureException = Sentry.captureException;
  captureEvent = Sentry.captureEvent;
  setContext = Sentry.setContext;

  exceptionQueue.forEach((x) => window.onerror?.(...x));
  rejectPromiseQueue.forEach((x) => window.onunhandledrejection?.(x));
  sentryQueue.forEach((x) => x(Sentry));
};

export let addBreadcrumb: SentryType["addBreadcrumb"] = (...args) => {
  if (!args[0].timestamp) {
    args[0].timestamp = Date.now() / 1000;
  }
  sentryQueue.push((x) => x.addBreadcrumb(...args));
};
export let setContext: SentryType["setContext"] = (...args) => {
  sentryQueue.push((x) => x.setContext(...args));
};
export let captureMessage: SetReturnType<SentryType["captureMessage"], void | string> = (...args) => {
  sentryQueue.push((x) => x.captureMessage(...args));
  loadSentry();
};
export let captureException: SetReturnType<SentryType["captureException"], void | string> = (...args) => {
  sentryQueue.push((x) => x.captureException(...args));
  loadSentry();
};
export let captureEvent: SetReturnType<SentryType["captureEvent"], void | string> = (...args) => {
  sentryQueue.push((x) => x.captureEvent(...args));
  loadSentry();
};

export const init = () => {
  window.onerror = (...args) => {
    exceptionQueue.push(args);
    loadSentry();
  };
  window.onunhandledrejection = (rejection) => {
    rejectPromiseQueue.push(rejection);
    loadSentry();
  };
};
