export function notNullish<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function notNull<TValue>(value: TValue | null): value is TValue {
  return value !== null;
}

export function notUndefined<TValue>(value: TValue | undefined): value is TValue {
  return value !== undefined;
}

export function notUnknown(x: unknown): x is { [key in PropertyKey]: unknown } {
  return x !== null && typeof x === "object";
}

export function entryNotNullish<TValue>(value: [string, TValue | null | undefined]): value is [string, TValue] {
  return value[1] !== null && value[1] !== undefined;
}
