import clsx from "clsx";
import React, { FunctionComponent, PropsWithChildren } from "react";

import styles from "./DrawerContainer.style.scss";

interface IDrawerContainerProps {
  className?: string;
}

const DrawerContainer: FunctionComponent<PropsWithChildren<IDrawerContainerProps>> = ({ children, className }) => {
  return <div className={clsx(styles.container, className)}>{children}</div>;
};

export const _DrawerContainer = DrawerContainer;
