import BagIcon from "icons/bag.svg";
import BasketIcon from "icons/basket.svg";
import BasketAltIcon from "icons/basket-alt.svg";
import CartIcon from "icons/cart.svg";
import CartAltIcon from "icons/cart-alt.svg";

export const BrandCartIcon =
  SETTINGS.DESIGN === "lavpris"
    ? CartAltIcon
    : SETTINGS.DESIGN === "greenline"
      ? BasketIcon
      : SETTINGS.DESIGN === "lampeguru"
        ? BagIcon
        : SETTINGS.DESIGN === "vvskupp"
          ? BasketAltIcon
          : CartIcon;
