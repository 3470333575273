import merge from "lodash/merge";

import { ICloudinaryFile } from "../../http/dto/Cloudinary.dto";

export interface ITransformations {
  width?: number;
  height?: number;
  quality?: string;
  cropMode?:
    | "scale"
    | "fill"
    | "fit"
    | "limit"
    | "pad"
    | "lpad"
    | "mpad"
    | "mfit"
    | "lfill"
    | "fill_pad"
    | "crop"
    | "thumb"
    | "imagga_crop"
    | "imagga_scale"
    | "format"
    | "backgroundColor";
  aspectRatio?: number | string;
  effect?: string;
  format?: "auto" | "jpg";
  backgroundColor?: string;
  asDownload?: boolean | string;
}

interface IBuildImageOptions {
  transformations?: ITransformations | ITransformations[];
  ext?: string;
  slug?: string;
  cloudName?: string;
  flattenTransformation?: boolean;
}

type ISrcSetTarget = {
  width: number;
  height?: number;
  queryWidth: number;
};

export function buildTransformations(transformations: ITransformations | ITransformations[]) {
  let transformationList: ITransformations[];
  if (!Array.isArray(transformations)) {
    transformationList = [transformations];
  } else {
    transformationList = transformations;
  }

  return transformationList
    .map((transformations) => {
      const result = [];
      if (transformations.width) {
        result.push(`w_${Math.round(transformations.width)}`);
      }

      if (transformations.height) {
        result.push(`h_${transformations.height}`);
      }

      if (transformations.quality) {
        result.push(`q_${transformations.quality}`);
      }

      if (transformations.cropMode) {
        result.push(`c_${transformations.cropMode}`);
      }

      if (transformations.effect) {
        result.push(`e_${transformations.effect}`);
      }

      if (transformations.aspectRatio) {
        result.push(`ar_${transformations.aspectRatio}`);
      }

      if (transformations.format) {
        result.push(`f_${transformations.format}`);
      }

      if (transformations.backgroundColor) {
        result.push(`b_${transformations.backgroundColor}`);
      }
      if (transformations.asDownload) {
        if (typeof transformations.asDownload === "boolean") {
          result.push("fl_attachment");
        } else {
          result.push(`fl_attachment:${transformations.asDownload}`);
        }
      }

      return result.join(",");
    })
    .join("/");
}

export function buildImage(cdn_id: string, cdn_version?: number, options?: IBuildImageOptions) {
  const opts: IBuildImageOptions = {
    ext: "jpg",
    ...options,
  };

  const result = [];

  if (!opts.slug) {
    result.push(`https://res.cloudinary.com/${options?.cloudName ?? "evoleska"}/image/upload`);
  } else {
    result.push(`https://res.cloudinary.com/${options?.cloudName ?? "evoleska"}/images`);
  }

  const transformations = buildTransformations(opts.transformations ?? []);
  if (transformations) {
    result.push(transformations);
  }

  if (cdn_version && cdn_version > 0) {
    result.push(`v${cdn_version}`);
  }

  result.push(cdn_id);

  if (opts.slug) {
    result.push(opts.slug);
  }

  return `${result.join("/")}.${opts.ext}`.replaceAll(" ", "%20");
}

export function buildCloudinaryImage(image: ICloudinaryFile, options?: Omit<IBuildImageOptions, "cloudName">) {
  return buildImage(image.public_id, image.version, {
    ...options,
    cloudName: image.cloud_name,
  });
}

export function buildSrcSet(
  cdn_id: string,
  cdn_version: number,
  targets: ISrcSetTarget[],
  options?: IBuildImageOptions,
) {
  const opts = {
    ext: options?.ext ?? "jpg",
    slug: options?.slug ?? undefined,
    cloudName: options?.cloudName,
  };

  let transformationList: ITransformations[];
  if (!options?.transformations) {
    transformationList = [];
  } else if (!Array.isArray(options?.transformations)) {
    transformationList = [options?.transformations];
  } else {
    transformationList = options?.transformations;
  }

  const result = targets.reduce<{ url: string[]; query: string[] }>(
    (accumulator, newValue, index) => {
      const finalTransformation = [...transformationList, { width: newValue.width, height: newValue.height }];
      const url = buildImage(cdn_id, cdn_version, {
        transformations: options?.flattenTransformation ? merge({}, ...finalTransformation) : finalTransformation,
        ...opts,
      });
      const width = Math.round(newValue.width);
      let query = `(max-width: ${newValue.queryWidth}) ${width}px`;

      if (index === targets.length - 1) {
        query = `${width}px`;
      }

      return {
        url: [...accumulator.url, `${url} ${width}w`],
        query: [...accumulator.query, query],
      };
    },
    { url: [], query: [] },
  );

  const last = targets[targets.length - 1];

  return {
    srcset: result.url.join(","),
    sizes: result.query.join(", "),
    fallback: buildImage(cdn_id, cdn_version, {
      transformations: [...transformationList, { width: last.width, height: last.height }],
      ...opts,
    }),
  };
}
