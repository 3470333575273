import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { FocusScope } from "react-aria";
import IconCloseThin from "svg/icon-close-thin.svg";

import { useKeyboard } from "../../hooks/useKeyboard";
import { _ModalButtonGroup } from "./components/ModalButtonGroup";
import { _ModalContent } from "./components/ModalContent";
import { _ModalHeader } from "./components/ModalHeader";
import { _ModalIcon } from "./components/ModalIcon";
import { ModalOverlay } from "./components/ModalOverlay";
import styles from "./Modal.style.scss";

export type ModalType = "info" | "error" | "default" | "success" | "warning";
export type ModalSize = "small" | "default" | "fullscreen" | "auto";

type ModalSubComponents = {
  Header: typeof _ModalHeader;
  Content: typeof _ModalContent;
  ButtonGroup: typeof _ModalButtonGroup;
  Icon: typeof _ModalIcon;
};

export interface IModalProps {
  onClose: () => void;
  type?: ModalType;
  size?: ModalSize;
  style?: React.CSSProperties;
  className?: string;
  "data-a-type"?: string;
}

const Modal: FunctionComponent<React.PropsWithChildren<IModalProps>> & ModalSubComponents = ({
  children,
  onClose,
  type,
  size,
  className,
  ...props
}) => {
  useKeyboard("esc", onClose);

  return (
    <ModalOverlay
      classNames={clsx(styles[`size-${size ?? "default"}`], styles[`type-${type ?? "default"}`])}
      onClose={onClose}
    >
      <FocusScope contain restoreFocus autoFocus>
        <div className={clsx(styles.modal, className)} {...props}>
          <div role="close" className={styles.desktopClose} onClick={onClose}>
            <IconCloseThin />
          </div>
          {children}
        </div>
        <div className={styles.mobileClose} onClick={onClose}>
          <IconCloseThin />
        </div>
      </FocusScope>
    </ModalOverlay>
  );
};

Modal.Content = _ModalContent;
Modal.Header = _ModalHeader;
Modal.ButtonGroup = _ModalButtonGroup;
Modal.Icon = _ModalIcon;

export default Modal;
