import Cookies from "js-cookie";
import { v4 as uuid } from "uuid";

import { serverTime } from "../../serverTime";
import { Analytics } from "../AnalyticsEvents";
import { IProductEvent } from "../events/product";

const ALGOLIA_POTENTIAL_CONVERSIONS = "algolia-potential-conversions";

interface IAlgoliaInsight {
  eventName?: string;
  eventType?: "view" | "click" | "conversion";
}

interface IAlgoliaFilterInsight extends IAlgoliaInsight {
  filters: string[];
  queryId?: string;
}

interface IAlgoliaProductInsight extends IAlgoliaInsight {
  objectId: string;
}

interface IAlgoliaProductSearchInsight extends IAlgoliaProductInsight {
  queryId: string;
  position: number;
  index: string;
}

function algoliaUserToken() {
  const token = Cookies.get("algolia_ut");
  if (token) {
    return token;
  }
  const newToken = uuid();
  Cookies.set("algolia_ut", newToken, { expires: 365 });
  return newToken;
}

export function algoliaInsights(props: IAlgoliaProductSearchInsight | IAlgoliaProductInsight | IAlgoliaFilterInsight) {
  if (SETTINGS.DEBUG) {
    return;
  }

  const index = "index" in props && props.index ? props.index : `${SETTINGS.SHOP_DEPARTMENT}_products`;

  if ("queryId" in props && props.queryId && "objectId" in props && props.objectId) {
    const conversions = JSON.parse(localStorage.getItem(ALGOLIA_POTENTIAL_CONVERSIONS) || "{}");
    // Beware, only the first queryid for an objectID is stored
    const newConversions = { [props.objectId]: { queryID: props.queryId, index }, ...conversions };
    localStorage.setItem(ALGOLIA_POTENTIAL_CONVERSIONS, JSON.stringify(newConversions));
  }

  fetch("https://insights.algolia.io/1/events", {
    headers: {
      "Content-Type": "application/json",
      "x-algolia-application-id": SETTINGS.ALGOLIA_APP_ID,
      "x-algolia-api-key": SETTINGS.ALGOLIA_KEY,
    },
    method: "POST",
    body: JSON.stringify({
      events: [
        {
          eventName: props.eventName ?? "Click item",
          eventType: props.eventType ?? "click",
          index: index,
          objectIDs: "objectId" in props ? [props.objectId] : undefined,
          positions: "position" in props ? [props.position] : undefined,
          filters: "filters" in props ? props.filters : undefined,
          queryID: "queryId" in props ? props.queryId : undefined,
          userToken: algoliaUserToken(),
        },
      ],
    }),
  });
}

const storeClickInLocalStorage = (event: IProductEvent) => {
  try {
    const conversions = JSON.parse(localStorage.getItem(ALGOLIA_POTENTIAL_CONVERSIONS) || "{}");
    const newConversions = { [event.id]: event.queryId, ...conversions };
    localStorage.setItem(ALGOLIA_POTENTIAL_CONVERSIONS, JSON.stringify(newConversions));
  } catch (error) {
    console.error(error); // Catch localStorage errors and ignore them
  }
};

const getClickFromLocalStorage = (id: string): string | undefined => {
  const conversions = JSON.parse(localStorage.getItem(ALGOLIA_POTENTIAL_CONVERSIONS) || "{}");
  return conversions[id];
};

export const initializeAlgoliaInsights = () => {
  Analytics.event("click", "product").addEventListener((event) => {
    if (event.queryId) {
      storeClickInLocalStorage(event);
      algoliaInsights({
        queryId: event.queryId,
        objectId: event.id,
        position: (event.position ?? 0) + 1,
        index: event.queryIndex,
      });
    } else {
      algoliaInsights({
        objectId: event.id,
      });
    }
  });
  Analytics.event("action", "addToBasket").addEventListener((event) => {
    algoliaInsights({
      queryId: getClickFromLocalStorage(event.id),
      objectId: event.id,
      eventType: "conversion",
      eventName: "AddBasket",
    });
  });
};
