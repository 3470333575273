import * as React from "react";
import { z } from "zod";

import { ProductBoxDataValidator } from "../../http/dto/ProductBoxData.dto";
import { renderReact } from "../../utils/hydrate";
import { retry } from "../../utils/retry";
import { notNullish } from "../../utils/types/notNull";
import AdminManager from "../admin/AdminManager";
import { ModalManager } from "../Modal/ModalManager";
import PowerStepManager from "../PowerStep/PowerstepManager";

const ProductBox = React.lazy(() => retry(() => import("../ProductBox/ProductBox")));

(async () => {
  const productBoxes = document.querySelectorAll(".js-product-box");
  // To avoid rendering multiple react contexts for product in the same list, and to avoid a wrapper element on the
  // productboxes, we find all unique parents of all productboxes on the current page
  const parents = Object.values(productBoxes).reduce<Element[]>((prev, current) => {
    if (current.parentElement && !prev.find((p: Element) => p.isSameNode(current.parentElement))) {
      prev.push(current.parentElement);
    }
    return prev;
  }, []);
  for (const parent of parents) {
    const children = parent.querySelectorAll(".js-product-box");
    const dataPromises = Object.values(children).map((child) => {
      if (!(child instanceof HTMLElement)) {
        return;
      }
      return z.array(ProductBoxDataValidator).parse(JSON.parse(child.dataset.json || "{}"));
    });

    const data = (await Promise.all(dataPromises)).flat().filter(notNullish);

    renderReact(
      parent,
      <AdminManager>
        <ModalManager>
          <PowerStepManager>
            <React.Suspense fallback={<li className={"productbox"} />}>
              {data.map((d, index) => (
                <ProductBox
                  key={d.productid}
                  product={d}
                  basket={true}
                  analytics={{
                    position: index,
                    list: "CMS Product List",
                  }}
                />
              ))}
            </React.Suspense>
          </PowerStepManager>
        </ModalManager>
      </AdminManager>,
    );
  }
})();
