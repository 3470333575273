import { IBaseProductEvent } from "../shared/utils/analytics/events/shared";
import { buildCloudinaryImage } from "../shared/utils/cloudinary/image";
import { ICartItem } from "./http/dto/Cart.dto";

export const cartItemToAnalytics = (line: ICartItem): IBaseProductEvent => {
  return {
    id: line.preview.ao_number ?? "unknown",
    name: line.preview.name,
    brand: line.analytics.brand,
    category: line.analytics.category,
    variant: line.preview.variant_name ?? undefined,
    price: line.price,
    normalPrice: line.normal_price,
    url: line.preview.url,
    image: line.preview.image ? buildCloudinaryImage(line.preview.image) : undefined,
  };
};
