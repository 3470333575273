export function onIntersection(query: Element | null, cb: () => void) {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        cb();
        observer.unobserve(entry.target);
      }
    });
  });
  if (query) {
    observer.observe(query);
  }
}
