import { Analytics } from "./AnalyticsEvents";
import { initializeAlgoliaInsights } from "./reporters/algolia";
import { initializeCriteo } from "./reporters/criteo";
import { initializeDatalayer } from "./reporters/datalayer";
import { initializeInsightReporter } from "./reporters/insight";
import { initializeKlaviyo } from "./reporters/klaviyo";

initializeDatalayer();
initializeAlgoliaInsights();
initializeInsightReporter();
initializeKlaviyo();
initializeCriteo();

document.addEventListener("DOMContentLoaded", async function () {
  if (SETTINGS.DEBUG) {
    await import("./reporters/debug").then(({ initializeDebugReporter }) => initializeDebugReporter());
  }

  Analytics.registerDomEvents();
  Analytics.collectDomEvents();
});
