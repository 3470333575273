/* eslint-disable no-restricted-syntax */
import { z } from "zod";

import { undefinable } from "../../zodHelpers";

export const AnalyticsMetadataValidator = z.object({
  page_type: undefinable(z.string()),
  page_name: undefinable(z.string()),
  page_id: undefinable(z.coerce.string()),
  first_visit: z.coerce.date().default(() => new Date()),
});
