import type { JsonObject } from "type-fest";

import { isHTMLElement } from "./types/isHTMLElement";

export const scriptData = (id: string): JsonObject | undefined => {
  const element = document.querySelector(`#${id}`);

  if (!isHTMLElement(element)) {
    return;
  }

  if (!element.textContent) {
    return;
  }

  return JSON.parse(element.textContent.trim());
};
