import { z } from "zod";

import { undefinable } from "../../zodHelpers";

/**
 * Promotions are like product impressions in that they, too, comprise a View and a Click.
 *
 * However, unlike any of the other Enhanced Ecommerce data types, promotions do not
 * involve products. Rather, you are measuring the promotions themselves. These would
 * typically be banners, product category highlights or something similar.
 */
export const PromotionEventValidator = z.object({
  /**
   * The id of this promotion inside of the CMS
   *
   * @example "storefrontbanner_685"
   */
  id: z.string(),
  /**
   * The name/description of the promotion content
   *
   * @example "Campaignbanner: Grohe sustainability – kategoribanner – uge 16-23"
   */
  name: z.string(),
  /**
   * Description of where does the promotion appear on the page
   *
   * The following values describes:
   *
   * "Header Message": Message below the menu
   * "Main Slider": Inside of a slider like on the homepage
   * "Banner": Full-width banner on the page
   * "Block": Banner in between products or on a communication block
   */
  position: undefinable(z.union([z.enum(["Header Message", "Main Slider", "Banner", "Block"]), z.string()])),
});

export type IPromotionEvent = z.infer<typeof PromotionEventValidator>;
