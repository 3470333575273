import { useEffect, useRef } from "react";

function useDebounce<T extends (...args: never[]) => unknown>(
  callback: T,
  delay: number,
): [(...args: Parameters<T>) => void, () => void] {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<NodeJS.Timeout>();

  // Update callback if it changes.
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return [
    (...args: Parameters<T>) => {
      // Clear previous timeouts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Start new timeout
      timeoutRef.current = setTimeout(() => {
        if (callbackRef.current) {
          callbackRef.current(...args);
        }
      }, delay);
    },
    () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
  ];
}

export default useDebounce;
