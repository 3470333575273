import * as React from "react";

import { BrandContentValidator } from "../../http/dto/BrandContent.dto";
import { renderReact } from "../../utils/hydrate";
import { retry } from "../../utils/retry";

document.addEventListener("DOMContentLoaded", async () => {
  const targetElems = document.querySelectorAll(".brands-slider-target");
  for (const element of targetElems) {
    const dataProp = element.getAttribute("data-prop");
    const data = JSON.parse(dataProp || "{}");
    const partners = BrandContentValidator.parse(data);
    const { default: BrandList } = await retry(() => import("./BrandSlider"));
    renderReact(element, <BrandList brandContent={partners} />);
  }
});
