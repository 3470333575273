import { differenceInSeconds } from "date-fns";
import { z } from "zod";
import { ZodType } from "zod/lib/types";

import { onIntersection } from "../intersection";
import * as Sentry from "../LazySentry";
import { serverTime } from "../serverTime";
import { isHTMLElement } from "../types/isHTMLElement";
import { isKeyOfObject } from "../types/isKeyOfObject";
import { AddToBasketEventValidator, addToBasketPreprocessor, IAddToBasketEvent } from "./events/addToBasket";
import { CheckoutEventValidator } from "./events/checkout";
import { ConsentValidator } from "./events/consent";
import { ExperimentInitializeEventValidator } from "./events/experiment";
import { ExperimentActivationValidator } from "./events/experimentActivation";
import { GuidanceValidator } from "./events/guidance";
import { AnalyticsMetadataValidator } from "./events/metadata";
import { NavigationEventValidator } from "./events/navigation";
import { PageViewValidator } from "./events/pageView";
import { ProductEventValidator } from "./events/product";
import { ProductDetailEventValidator } from "./events/productDetail";
import { PromotionEventValidator } from "./events/promotion";
import { RemoveFromBasketEventValidator } from "./events/removeFromBasket";
import { SearchEventValidator } from "./events/search";
import { SelectionEventValidator } from "./events/selection";
import { getAnalyticsAttributes, getAnalyticsDestinationAttributes } from "./utils/analytics-attributes";

const getAttribute = (element: HTMLElement, key: string) => {
  const actualElement = element.closest(`[${key}]`);
  if (!actualElement) {
    return undefined;
  }
  return actualElement.getAttribute(`${key}`) ?? undefined;
};

interface IEventManagerOptions<RawEvent, Processed> {
  /**
   * Deduplicate events
   */
  deduplicate?: {
    /**
     * The type of deduplication to use
     *
     * "in-memory" - events will only be persisted in memory, and will be lost on page refresh
     * "persistent" - events will be persisted in local storage, and will be kept on page refresh
     */
    type: "in-memory" | "persistent";
  };
  preprocessor?: (event: RawEvent) => Processed;
}

interface IAnalyticsMetadata {
  page_type?: string;
  page_name?: string;
  page_id?: string;
  session_time: number;
}

class Memorizer<E> {
  protected memorizer: Set<string> = new Set();

  add(event: E) {
    const key = JSON.stringify(event);
    this.memorizer.add(key);
  }

  has(event: E) {
    const key = JSON.stringify(event);
    return this.memorizer.has(key);
  }

  clear() {
    this.memorizer.clear();
  }
}

class LocalStorageMemorizer<E> extends Memorizer<E> {
  constructor() {
    super();
    if (!global.window) {
      return;
    }
    const stored = localStorage.getItem("analytics-memorizer");
    if (stored) {
      this.memorizer = new Set(JSON.parse(stored));
    }
  }

  add(event: E) {
    super.add(event);
    if (!global.window) {
      return;
    }

    try {
      localStorage.setItem("analytics-memorizer", JSON.stringify([...this.memorizer]));
    } catch (error) {
      // Might error if the local storage is full
    }
  }

  clear() {
    super.clear();
    if (global.window) {
      return;
    }
    localStorage.removeItem("analytics-memorizer");
  }
}

export class EventManager<
  Validator extends ZodType,
  RawEvent extends Validator["_output"],
  Processed extends RawEvent,
> {
  private handlers: ((payload: Processed) => void)[] = [];
  private batchHandlers: ((payload: Processed[]) => void)[] = [];
  private batch: Processed[] = [];
  private currentTimeout?: ReturnType<typeof setTimeout>;

  private validator: Validator;
  private memorizer?: Memorizer<RawEvent>;
  private options: IEventManagerOptions<RawEvent, Processed>;
  private readonly preprocessor: (event: RawEvent) => Processed;

  constructor(validator: Validator, options?: IEventManagerOptions<RawEvent, Processed>) {
    this.validator = validator;
    this.options = options ?? {};
    this.preprocessor = this.options.preprocessor ?? ((event) => event as Processed);

    if (this.options.deduplicate?.type === "persistent") {
      this.memorizer = new LocalStorageMemorizer();
    } else if (this.options.deduplicate?.type === "in-memory") {
      this.memorizer = new Memorizer();
    }
  }

  /**
   * Add event listener
   *
   * @param handler - A callback that will be triggered on event dispatch
   */
  addEventListener(handler: (event: Processed) => void) {
    this.handlers.push(handler);
  }

  /**
   * Remove an event listener
   *
   * @param handler - The callback to deregister
   */
  removeEventListener(handler: (event: Validator["_output"]) => void) {
    const index = this.handlers.indexOf(handler);
    if (index && index > -1) {
      this.handlers.splice(index, 1);
    }
  }

  /**
   * Add a batch event listener
   *
   * @remarks similar to {@link EventManager.addEventListener}
   *
   * @param handler - A callback that will be triggered on a batch dispatch
   */
  addBatchEventListener(handler: (event: Validator["_output"][]) => void) {
    this.batchHandlers.push(handler);
  }

  /**
   * Remove an event listener
   *
   * @remarks similar to {@link EventManager.removeEventListener}
   *
   * @param handler - The callback to deregister
   */
  removeBatchEventListener(handler: (event: Validator["_output"][]) => void) {
    const index = this.batchHandlers.indexOf(handler);
    if (index && index > -1) {
      this.batchHandlers.splice(index, 1);
    }
  }

  /**
   * Fire all batched events to all registed event batch listerners
   */
  private fireBatchEvents() {
    const batch = [...this.batch];
    this.batch = [];
    this.batchHandlers.forEach((handler) => {
      try {
        handler(batch);
      } catch (error) {
        Sentry.captureException(error);
      }
    });
  }

  /**
   * Dispatch an event
   *
   * @param event - The event to dispatch
   */
  dispatchEvent(event: Validator["_output"]) {
    if (this.memorizer?.has(event)) {
      return;
    }
    this.memorizer?.add(event);
    const processedEvent = this.preprocessor(event);

    this.handlers.forEach((handler) => {
      try {
        handler(processedEvent);
      } catch (error) {
        Sentry.captureException(error, { extra: { event: JSON.stringify(event) } });
      }
    });
    if (this.currentTimeout !== undefined) {
      clearTimeout(this.currentTimeout);
    }
    // A dubious optimization, which might cause batch handlers which register
    // late to miss some events, but it's better than wasting memory on batching
    // events that never will be dispatched
    if (this.batchHandlers.length > 0) {
      this.batch.push(processedEvent);
      this.currentTimeout = setTimeout(() => this.fireBatchEvents(), 500);
    }
  }

  /**
   * Parse and dispatch an event
   */
  dispatchRawEvent(event: string | { [key: string]: unknown }) {
    let _event: string | { [key: string]: unknown } = event;

    if (typeof _event === "string") {
      _event = JSON.parse(_event);
    }

    this.dispatchEvent(this.validator.parse(_event));
  }
}

/**
 * The global analytics objects
 */
export class Analytics {
  static _events = {
    impression: {
      pageView: new EventManager(PageViewValidator),
      product: new EventManager(ProductEventValidator, { deduplicate: { type: "in-memory" } }),
      productDetail: new EventManager(ProductDetailEventValidator),
      promotion: new EventManager(PromotionEventValidator),
    },
    click: {
      product: new EventManager(ProductEventValidator),
      promotion: new EventManager(PromotionEventValidator),
    },
    action: {
      consent: new EventManager(ConsentValidator, { deduplicate: { type: "persistent" } }),
      addToBasket: new EventManager(AddToBasketEventValidator, {
        preprocessor: addToBasketPreprocessor,
      }),
      selection: new EventManager(SelectionEventValidator, { deduplicate: { type: "in-memory" } }),
      removeFromBasket: new EventManager(RemoveFromBasketEventValidator),
      checkout: new EventManager(CheckoutEventValidator), // TODO: This should be split into seperate basket, add_shipping_info, add_payment_info and begin_checkout events
      guidance: new EventManager(GuidanceValidator),
      navigation: new EventManager(NavigationEventValidator),
      search: new EventManager(SearchEventValidator, { deduplicate: { type: "in-memory" } }),
      nextPage: new EventManager(z.object({})),
    },
    experiment: {
      activation: new EventManager(ExperimentActivationValidator),
      initialize: new EventManager(ExperimentInitializeEventValidator, { deduplicate: { type: "in-memory" } }),
    },
  } as const;

  private constructor() {
    throw new Error("The analytics object is not meant to be initialized");
  }

  /**
   * Get event handler for a specific group/event combination
   *
   * @param group - Which event group to use
   * @param event - Which event to use
   */
  static event<Group extends keyof typeof Analytics._events, E extends keyof (typeof Analytics._events)[Group]>(
    group: Group,
    event: E,
  ): (typeof Analytics._events)[Group][E] {
    return Analytics._events[group][event];
  }

  static get metadata(): IAnalyticsMetadata {
    if (!global.window || !window.ANALYTICS_METADATA) {
      return {
        session_time: 0,
      };
    }
    const metadata = AnalyticsMetadataValidator.parse(window.ANALYTICS_METADATA);
    return {
      page_type: metadata.page_type,
      page_name: metadata.page_name,
      page_id: metadata.page_id,
      session_time: differenceInSeconds(serverTime(), metadata.first_visit),
    };
  }

  /**
   * Collects and sends a page view event
   */
  static pageView() {
    Analytics.event("impression", "pageView").dispatchEvent({
      url: window.location.pathname + window.location.search + window.location.hash,
      screen_width: window.screen.width,
      screen_height: window.screen.height,
      user_agent: navigator.userAgent,
      referrer: document.referrer,
      ...this.metadata,
    });
    this.endNavigation();
  }

  /**
   * Collects start navigation event, stores them if they are internal links and emits them if they are external links

   * @param aElement - The anchor element that was clicked
   */
  static startNavigation(aElement: HTMLAnchorElement) {
    if (!aElement.href) {
      return;
    }

    const url = new URL(aElement.href);
    const sourcePath = window.location.pathname + window.location.search + window.location.hash;

    const { type: sourceType, name: sourceName, id: sourceId } = getAnalyticsAttributes(aElement);

    // If the destination is an external, emit a event and return
    if (url.origin !== window.location.origin) {
      const { type: destType, name: destName, id: destId } = getAnalyticsDestinationAttributes(aElement);
      return Analytics.event("action", "navigation").dispatchEvent({
        source_url: sourcePath,
        destination_url: url.href,
        source_type: sourceType,
        source_name: sourceName,
        source_id: sourceId,
        destination_type: destType,
        destination_name: destName,
        destination_id: destId,
        session_time: this.metadata.session_time,
      });
    }

    // If the destination is internal, store the path in local storage
    const paths = JSON.parse(localStorage.getItem("navigation") ?? "{}");
    paths[url.pathname] = {
      source_url: sourcePath,
      source_type: sourceType,
      source_name: sourceName,
      source_id: sourceId,
    };
    try {
      localStorage.setItem("navigation", JSON.stringify(paths));
    } catch (error) {
      // Might error if the local storage is full
      console.error(error);
    }
  }

  /**
   * Triggers internal navigation event, if the path is set in local storage by startNavigation
   */
  static endNavigation() {
    const storedPaths = JSON.parse(localStorage.getItem("navigation") ?? "{}");
    const destinationPath = window.location.pathname + window.location.search + window.location.hash;
    const source = storedPaths[window.location.pathname];

    if (source) {
      Analytics.event("action", "navigation").dispatchEvent({
        source_url: source.source_url,
        source_type: source.source_type,
        source_name: source.source_name,
        source_id: source.source_id,
        destination_url: destinationPath,
        destination_type: this.metadata.page_type,
        destination_name: this.metadata.page_name,
        destination_id: this.metadata.page_id,
        session_time: this.metadata.session_time,
      });
    }

    if (source && this.metadata.page_type === "product-page" && this.metadata.page_id) {
      // If the page is a product page, we want to store the product id in the local storage for use add to basket
      const aonumber = this.metadata.page_id;
      const productNavigation = JSON.parse(localStorage.getItem("product-navigation") ?? "{}");
      productNavigation[aonumber] = {
        source_url: source.source_url,
        source_type: source.source_type,
        source_name: source.source_name,
        source_id: source.source_id,
      };
      try {
        localStorage.setItem("product-navigation", JSON.stringify(productNavigation));
      } catch (error) {
        console.error(error);
      }
    }

    delete storedPaths[window.location.pathname];
    try {
      localStorage.setItem("navigation", JSON.stringify(storedPaths));
    } catch (error) {
      // Might error if the local storage is full
      console.error(error);
    }
  }

  /**
   * Registers handlers for all DOM events with analytics datasets.
   *
   * @remarks Dataset analytics should only be used by native HTML, NOT REACT
   *
   * @example
   * ```html
   * <div
   *  data-analytics-event="product"
   *  data-analytics-group="click,impression"
   *  data-analytics-payload="{... payload ...}"
   *  >
   *     ...
   *  </div>
   * ```
   */
  static registerDomEvents() {
    this.pageView();

    // Track selections
    document.querySelectorAll("[data-a-selection]").forEach((element) => {
      // We could probably avoid attaching this event listener to every single element
      // but we are only tracking a few elements, so it should be fine
      document.addEventListener("selectionchange", (e) => {
        const selection = window.getSelection();
        const elementName = element.getAttribute("data-a-selection");
        if (selection?.containsNode(element, true) && selection.toString().length > 0 && elementName) {
          Analytics.event("action", "selection").dispatchEvent({
            elementName,
          });
        }
      });
    });

    window.addEventListener("CookieInformationConsentGiven", () => {
      Analytics.event("action", "consent").dispatchEvent({
        marketing: global.window.CookieInformation?.getConsentGivenFor("marketing") ?? false,
        statistic: global.window.CookieInformation?.getConsentGivenFor("statistic") ?? false,
        functional: global.window.CookieInformation?.getConsentGivenFor("functional") ?? false,
      });
    });

    const domElements = document.querySelectorAll(
      "[data-analytics-event][data-analytics-group][data-analytics-payload]",
    );

    for (const element of domElements) {
      if (!isHTMLElement(element)) {
        continue;
      }

      if (!element.dataset.analyticsEvent || !element.dataset.analyticsGroup || !element.dataset.analyticsPayload) {
        continue;
      }

      const { analyticsEvent, analyticsGroup, analyticsPayload } = element.dataset;

      const groups = analyticsGroup.includes(",") ? analyticsGroup.split(",") : [analyticsGroup];
      const event = analyticsEvent;

      if (groups.includes("click") && isKeyOfObject(event, Analytics._events.click)) {
        element.addEventListener("click", (e) => {
          Analytics.event("click", event).dispatchRawEvent(analyticsPayload);
        });
      }

      if (groups.includes("action") && isKeyOfObject(event, Analytics._events.action)) {
        element.addEventListener("click", (e) => {
          Analytics.event("action", event).dispatchRawEvent(analyticsPayload);
        });
      }

      if (groups.includes("action") && isKeyOfObject(event, Analytics._events.impression)) {
        onIntersection(element, () => {
          Analytics.event("impression", event).dispatchRawEvent(analyticsPayload);
        });
      }
    }

    document.addEventListener("click", (e) => {
      // Check if the element (or any of its parents) is an anchor element
      const aElement =
        e.target instanceof HTMLAnchorElement
          ? e.target
          : e.target instanceof HTMLElement
            ? e.target.closest("a")
            : undefined;
      if (aElement) {
        Analytics.startNavigation(aElement);
      }
    });
  }

  /**
   * Collects, and sends analytics events registered in the DOM
   *
   * This is useful to add events that should trigger on page load
   *
   * @example
   * ```html
   * <script
   *  type="application/json"
   *  data-analytics-event="checkout"
   *  data-analytics-group="action"
   *  >
   *     {... payload ...}
   *  </div>
   * ```
   */
  static collectDomEvents() {
    const domElements = document.querySelectorAll(
      "script[data-analytics-event][data-analytics-group][type='application/json']",
    );

    for (const element of domElements) {
      if (!isHTMLElement(element)) {
        continue;
      }

      if (!element.dataset.analyticsEvent || !element.dataset.analyticsGroup) {
        continue;
      }

      const { analyticsEvent, analyticsGroup } = element.dataset;
      const rawPayload = element.innerHTML;

      if (
        !isKeyOfObject(analyticsGroup, Analytics._events) ||
        !isKeyOfObject(analyticsEvent, Analytics._events[analyticsGroup])
      ) {
        console.error(`AnalyticsEvents: "${analyticsGroup}/${analyticsEvent}" is not supported`);
        return;
      }

      // For some reason typescript isn't able to look up analyticsEvent as a key of `Analytics._events[analyticsGroup]`
      // which causes the type of `Analytics._events[analyticsGroup][analyticsEvent]` to be never, but since we use
      // the dispatchRawEvent method, we can just cast the event manager to an unknown type
      const x = Analytics._events[analyticsGroup][analyticsEvent] as EventManager<ZodType<unknown>, unknown, unknown>;

      x.dispatchRawEvent(rawPayload);
    }
  }
}
