import Cookies from "js-cookie";

import { Analytics } from "../../shared/utils/analytics/AnalyticsEvents";
import { isHTMLElement, isHTMLInputElement } from "../../shared/utils/types/isHTMLElement";

const triggerOnClick = (selector: string, callback: (element: HTMLElement) => void) => {
  [...document.querySelectorAll(selector)].filter(isHTMLElement).forEach((x) =>
    x.addEventListener("click", (event) => {
      const target = event.currentTarget;

      if (!isHTMLElement(target)) {
        return;
      }

      const href = target.getAttribute("href");
      const hrefTarget = target.getAttribute("target");

      if (href && hrefTarget !== "_blank") {
        event.preventDefault();
      }

      callback(target);

      if (href && hrefTarget !== "_blank") {
        window.location.href = href;
      }
    }),
  );
};

(() => {
  /* trigger profitmetrics identify events */
  triggerOnClick("[data-pm-identifier-target]", (target) => {
    const identifier = target.dataset.pmIdentifierTarget as string;
    const element = document.querySelector(identifier);

    if (window.profitMetrics?.setEmail && isHTMLInputElement(element)) {
      window.profitMetrics.setEmail(element.value);
    }
  });
})();
