import { z } from "zod";

import { CartValidator } from "../../../../cart/http/dto/Cart.dto";
import { undefinable } from "../../zodHelpers";
import { IProductEvent } from "./product";
import { AlgoliaProductEventValidator, BaseProductEventValidator } from "./shared";

export const AddToBasketSourceValidator = undefinable(
  z.union([
    z.literal("product-card"),
    z.literal("product-box"),
    z.literal("iu-modal"),
    z.literal("bundle"),
    z.literal("powerstep"),
    z.literal("accessory"),
    z.literal("basket"),
    z.literal("iu-basket"),
  ]),
);

export const AddToBasketEventValidator = BaseProductEventValidator.merge(
  z.object({
    /**
     * The quantity number of the product being added to the basket
     *
     * @example 2
     */
    quantity: z.number(),
    priceDkk: z.coerce.number(),
    source: AddToBasketSourceValidator,
    cart: CartValidator,
  }),
);

export const addToBasketPreprocessor = (event: IAddToBasketEvent) => {
  if (event.source !== "product-card") {
    // Only product-card events should have navigation data
    return event;
  }
  const navigation = JSON.parse(localStorage.getItem("product-navigation") ?? "{}")[event.id];
  if (!navigation) {
    return event;
  }
  return {
    ...event,
    navigation_source_url: navigation.source_url,
    navigation_source_type: navigation.source_type,
    navigation_source_name: navigation.source_name,
    navigation_source_id: navigation.source_id,
  } as const;
};

export type IAddToBasketEvent = z.infer<typeof AddToBasketEventValidator>;

export const SOURCE_NAMES: { [key in NonNullable<z.infer<typeof AddToBasketSourceValidator>>]: string } = {
  "product-card": "Product Card",
  "product-box": "Product Box",
  "iu-modal": "IU Modal",
  bundle: "Bundle",
  powerstep: "Powerstep",
  accessory: "Accessory",
  basket: "Basket",
  "iu-basket": "IU Basket",
};
