import { z } from "zod";

import { undefinable } from "../../utils/zodHelpers";

export const ENVIRONMENT_FILETYPES = ["miljobilleder", "zoomfoto"];

export const ResourceValidator = z.object({
  cdn_id: z.string(),
  cdn_version: z.number().int(),
  type: z.string(),
  slug: undefinable(z.string()),
  resource_text: undefinable(z.string()),
  filetype: undefinable(z.string()),
});

export type IResource = z.infer<typeof ResourceValidator>;
