import { CmsSectionValidator, ICmsSection } from "./dto/Cms.dto";
import { request } from "./HTTP";

export async function fetchCmsPage(section: string): Promise<string> {
  const res = await request(`${section}?nowrap=true`);
  return res.text();
}

export async function fetchCmsSection(section: string): Promise<ICmsSection> {
  const res = await request(`/cms/${section}`);
  return CmsSectionValidator.parse(await res.json());
}
