import clsx from "clsx";
import React, { FunctionComponent, PropsWithChildren } from "react";

import styles from "./ModalButtonGroup.style.scss";

interface IModalButtonGroup {
  className?: string;
}

const ModalButtonGroup: FunctionComponent<PropsWithChildren<IModalButtonGroup>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={clsx(className, styles.content)} {...props}>
      {children}
    </div>
  );
};

export const _ModalButtonGroup = ModalButtonGroup;
