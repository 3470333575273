import { configuration } from "../../../modules/Configuration/Configuration";
import { Analytics } from "../AnalyticsEvents";
import { IAddToBasketEvent, SOURCE_NAMES } from "../events/addToBasket";
import { IProductEvent, LIST_IDS } from "../events/product";
import { IBaseProductEvent } from "../events/shared";

const formatNumber = (number: number): string => {
  return number.toFixed(2);
};

const formatName = (string: string): string => {
  return string.slice(0, 99).replace(/"/g, "");
};

const convertToEcommerceObject = (
  event: IProductEvent | IAddToBasketEvent | (IBaseProductEvent & { coupon?: string }),
) => {
  const result: { [key: string]: string | number | undefined } = {
    item_id: event.id,
    item_name: formatName(event.name),
    index: "position" in event ? event.position : 0,
    item_brand: event.brand,
    item_category: event.category,
    item_variant: event.variant,
    price: formatNumber(event.price),
    quantity: 1,
  };

  if ("list" in event) {
    result.item_list_id = event.list && LIST_IDS[event.list];
    result.item_list_name = event.list;
  }
  if ("source" in event) {
    result.item_list_id = event.source;
    result.item_list_name = event.source && SOURCE_NAMES[event.source];
    result.quantity = event.quantity;
  }

  if ("coupon" in event) {
    result.coupon = event.coupon;
  }

  return result;
};

export const initializeDatalayer = () => {
  if (window.dataLayer === undefined) {
    window.dataLayer = [];
  }

  const conf = configuration();

  Analytics.event("impression", "promotion").addEventListener((event) => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_promotion",
      ecommerce: {
        creative_slot: event.position,
        promotion_name: formatName(event.name),
        promotion_id: event.id,
      },
    });
  });

  Analytics.event("click", "promotion").addEventListener((event) => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "select_promotion",
      ecommerce: {
        creative_slot: event.position,
        promotion_name: formatName(event.name),
        promotion_id: event.id,
      },
    });
  });

  Analytics.event("impression", "product").addBatchEventListener((events) => {
    // Group events by list
    const groupedEvents = events.reduce<{ [key: string]: IProductEvent[] }>((acc, event) => {
      const list = event.list ?? "unknown";
      if (!acc[list]) {
        acc[list] = [];
      }
      acc[list].push(event);
      return acc;
    }, {});

    Object.entries(groupedEvents).forEach(([list, event]) => {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          currency: conf.SHOP_CURRENCY,
          item_list_id: event[0].list && LIST_IDS[event[0].list],
          item_list_name: list,
          items: event.map(convertToEcommerceObject),
        },
      });
    });
  });

  Analytics.event("click", "product").addEventListener((event) => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        currency: conf.SHOP_CURRENCY,
        item_list_id: event.list && LIST_IDS[event.list],
        item_list_name: event.list,
        items: [convertToEcommerceObject(event)],
      },
    });
  });

  Analytics.event("impression", "productDetail").addEventListener((event) => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: conf.SHOP_CURRENCY,
        value: formatNumber(event.price),
        items: [{ ...convertToEcommerceObject(event), item_list_id: "product-page", item_list_name: "Product Page" }],
      },
    });
  });

  Analytics.event("action", "addToBasket").addEventListener((event) => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: conf.SHOP_CURRENCY,
        value: formatNumber(event.price),
        items: [convertToEcommerceObject(event)],
      },
    });
  });

  Analytics.event("action", "removeFromBasket").addEventListener((event) => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        currency: conf.SHOP_CURRENCY,
        value: formatNumber(event.price),
        items: [convertToEcommerceObject(event)],
      },
    });
  });

  Analytics.event("action", "checkout").addEventListener((event) => {
    const eventName = {
      1: "view_cart",
      2: "begin_checkout",
      3: "add_shipping_info",
      4: "add_payment_info",
    };
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: eventName[event.step],
      ecommerce: {
        currency: conf.SHOP_CURRENCY,
        value: formatNumber(event.products.reduce((acc, product) => acc + product.price * product.quantity, 0)),
        coupon: event.products.find((p) => p.coupon)?.coupon,
        shipping_tier: event.step === 3 ? event.option : undefined,
        payment_method: event.step === 4 ? event.option : undefined,
        items: event.products.map(convertToEcommerceObject),
      },
    });
  });

  Analytics.event("action", "guidance").addEventListener((event) => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "guidance",
      guidanceAnswer: event.answer,
      guidanceAnswerCount: event.answerCount,
      guidanceQuestion: event.question,
    });
  });

  Analytics.event("experiment", "activation").addEventListener((event) => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "experimentActivation",
      activationType: event.type,
      activationSource: event.source,
    });
  });

  Analytics.event("experiment", "initialize").addEventListener((event) => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "experimentInitialized",
      experimentId: event.experiment,
      variantId: event.variant,
    });
  });

  Analytics.event("action", "navigation").addEventListener((event) => {
    if (event.source_type === "megamenu") {
      window.dataLayer.push({
        event: "menu_click",
        menuName: event.source_name,
        menuLink: event.destination_url,
      });
    }
  });
};
