import { notUnknown } from "./notNull";

export function isHTMLElement(element: unknown): element is HTMLElement {
  if (!notUnknown(element)) {
    return false;
  } else {
    return element instanceof HTMLElement;
  }
}

export function isHTMLFormElement(element: unknown): element is HTMLFormElement {
  if (!notUnknown(element)) {
    return false;
  } else {
    return element instanceof HTMLFormElement;
  }
}

export function isHTMLInputElement(element: unknown): element is HTMLInputElement {
  if (!notUnknown(element)) {
    return false;
  } else {
    return element instanceof HTMLInputElement;
  }
}
