const getAttribute = (element: HTMLElement, key: string) => {
  const actualElement = element.closest(`[${key}]`);
  if (!actualElement) {
    return undefined;
  }
  return actualElement.getAttribute(`${key}`) ?? undefined;
};

export interface IAnalyticsAttributes {
  type?: string;
  name?: string;
  id?: string;
}

export const getAnalyticsAttributes = (element: HTMLElement): IAnalyticsAttributes => {
  return {
    type: getAttribute(element, "data-a-type"),
    name: getAttribute(element, "data-a-name"),
    id: getAttribute(element, "data-a-id"),
  };
};

export const getAnalyticsDestinationAttributes = (element: HTMLElement): IAnalyticsAttributes => {
  return {
    type: getAttribute(element, "data-a-dest-type"),
    name: getAttribute(element, "data-a-dest-name"),
    id: getAttribute(element, "data-a-dest-id"),
  };
};
