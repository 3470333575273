import { z } from "zod";

import { CartValidator } from "../../../../cart/http/dto/Cart.dto";
import { BaseProductEventValidator } from "./shared";

export const RemoveFromBasketEventValidator = BaseProductEventValidator.merge(
  z.object({
    /**
     * The quantity number of the product being removed to the basket
     *
     * @example 2
     */
    quantity: z.number(),
    cart: CartValidator,
  }),
);

export type IRemoveFromBasketEvent = z.infer<typeof RemoveFromBasketEventValidator>;
