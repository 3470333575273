/* eslint-disable no-restricted-syntax */
import { z } from "zod";

export const SearchEventValidator = z.object({
  source_url: z.string(),
  source_type: z.string().optional(),
  source_name: z.string().optional(),
  source_id: z.coerce.string().optional(),
  filters: z.record(z.string(), z.string()),
});

export type ISearchEvent = z.infer<typeof SearchEventValidator>;
