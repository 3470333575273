import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";

import { LoadingAnimation } from "../../../customercenter/modules/LoadingAnimation/LoadingAnimation";
import { retry } from "../../utils/retry";
import Modal from "../Modal/Modal";
import { PowerStepProvider } from "./PowerstepContext";

const PowerStep = React.lazy(() => retry(() => import("./PowerStep")));

const PowerStepManager: FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const [products, setProducts] = useState<string[]>();
  const closePromise = useRef<() => void>();

  const onClose = useCallback(() => {
    closePromise.current?.();
    setProducts(undefined);
  }, []);

  const activeProduct = useMemo(() => products?.[0], [products]);

  return (
    <PowerStepProvider
      value={{
        powerStep: (productsIds: string[]) => {
          setProducts(productsIds);
          // Gives the consumer the ability to wait for the modal to close
          return new Promise((resolve) => {
            // Resolve previous promise to avoid multiple de-referenced promises,
            // there are no use cases for multiple powersteps anyways
            if (closePromise.current) {
              closePromise.current();
            }
            closePromise.current = resolve;
          });
        },
      }}
    >
      {activeProduct && (
        <React.Suspense
          fallback={
            <Modal size="small" onClose={onClose}>
              <LoadingAnimation size={100} />
            </Modal>
          }
        >
          <PowerStep productId={activeProduct} onClose={onClose} />
        </React.Suspense>
      )}
      {children}
    </PowerStepProvider>
  );
};

export default PowerStepManager;
