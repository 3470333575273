import isHotkey from "is-hotkey";
import React, { useEffect } from "react";

/*
  Run callback on keyboard event
 */
export function useKeyboard(
  hotkey: ((event: KeyboardEvent) => boolean) | string | number,
  callback: (event: KeyboardEvent) => void,
  ref?: React.RefObject<HTMLElement>,
) {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (typeof hotkey === "string") {
        if (isHotkey(hotkey)(event)) {
          callback(event);
        }
      } else if (typeof hotkey === "number") {
        if (event.keyCode === hotkey) {
          callback(event);
        }
      } else if (hotkey(event)) {
        callback(event);
      }
    };

    const listener = ref?.current;

    if (listener) {
      listener.addEventListener("keydown", handler);
    } else {
      document.addEventListener("keydown", handler);
    }
    return () => {
      if (listener) {
        listener.removeEventListener("keydown", handler);
      } else {
        document.removeEventListener("keydown", handler);
      }
    };
  }, [hotkey, callback, ref]);
}
