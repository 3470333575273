import { Dispatch, SetStateAction, startTransition, useCallback, useEffect, useState } from "react";

import * as Sentry from "../utils/LazySentry";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface WindowEventMap {
    "local-storage": StorageEvent;
  }
}

export function useLocalStorage<T>(key: string, initialState: T | (() => T)): [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialState;
    }
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialState;
  });

  const setValue = (value: T | SetStateAction<T>) => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    const json = JSON.stringify(valueToStore);
    window.localStorage.setItem(key, json);
    // Custom event to notify in the same tab (the storage event doesn't work in the same tab)
    window.dispatchEvent(new StorageEvent("local-storage", { key, newValue: json }));
  };

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === key) {
        startTransition(() => {
          setStoredValue(JSON.parse(e.newValue || "null"));
        });
      }
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("local-storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("local-storage", handleStorageChange);
    };
  }, [key]);

  return [storedValue, setValue];
}

export function useSafeLocalStorage<T>(key: string, initialState: T | (() => T)): [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = global?.window?.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialState;
  });

  const setValue = useCallback(
    (value: T | SetStateAction<T>) => {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      try {
        global?.window?.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [key, storedValue],
  );

  return [storedValue, setValue];
}
