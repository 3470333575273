import { z } from "zod";

import { undefinable } from "../../utils/zodHelpers";

export const SuccessResponseValidator = <C extends z.ZodTypeAny>(codec: C) =>
  z.object({
    data: codec,
    status: z.literal("success"),
  });

const renameErrorCode = <T extends z.ZodTypeAny>(codec: T) =>
  z.preprocess((data) => {
    if (data && typeof data === "object" && "code" in data) {
      return {
        ...data,
        error_code: data.code,
      };
    }
    return data;
  }, codec);

export const ErrorResponseValidator = <C extends z.ZodTypeAny>(codec: C) =>
  z.object({
    data: codec,
    error_code: z.string(),
    message: undefinable(z.string()),
    status: z.literal("error"),
  });

export const BaseResponseValidator = <C extends z.ZodType, E extends z.ZodTypeAny>(successCodec: C, errorCodec: E) => {
  return renameErrorCode(z.union([SuccessResponseValidator(successCodec), ErrorResponseValidator(errorCodec)]));
};
