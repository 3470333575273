import { z } from "zod";

import { undefinable } from "../../../shared/utils/zodHelpers";

export const AwardValidator = z.object({
  image_url: z.string(),
  resource_text: undefinable(z.string()),
});

export type IAward = z.infer<typeof AwardValidator>;
