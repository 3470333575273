import { z } from "zod";

import { undefinable } from "../../utils/zodHelpers";

/**
 * This DTO represents a cloudinary file
 *
 * @see {@link lib/bvvs/core/integrations/cloudinary.py#CloudinaryFile | CloudinaryFile} for the python version of this data class
 */
export const CloudinaryFileValidator = z.object({
  public_id: z.string(),
  version: z.number().int(),
  slug: undefinable(z.string()),
  cloud_name: undefinable(z.string()),
  type: z.enum(["image", "video"]),
});

export type ICloudinaryFile = z.infer<typeof CloudinaryFileValidator>;
