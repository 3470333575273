import { z } from "zod";

export const ExperimentActivationValidator = z.object({
  /**
   * Type of action or criteria for the event
   */
  type: z.literal("hasViewed"),
  /**
   * The source of the experiment activation
   *
   * @example "Product Page Similar Product Slider"
   */
  source: z.string(),
});

export type IExperimentActivation = z.infer<typeof ExperimentActivationValidator>;
