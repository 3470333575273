import clsx from "clsx";
import React, { FunctionComponent } from "react";

import { ModalSize } from "../Modal";
import styles from "./ModalHeader.style.scss";
interface IModalHeaderProps {
  title: string;
  className?: string;
  subtitle?: string;
  size?: ModalSize;
}

const ModalHeader: FunctionComponent<React.PropsWithChildren<IModalHeaderProps>> = ({
  children,
  title,
  className,
  subtitle,
  size,
  ...props
}) => {
  return title ? (
    <div className={clsx(className, styles[`size-${size ?? "default"}`])} {...props}>
      {size === "fullscreen" ? (
        <h2 className={styles.fullscreenHeader}>{title}</h2>
      ) : (
        <div className={styles.header}>{title}</div>
      )}
      {subtitle ? <h6 className={styles.subtitle}>{subtitle}</h6> : undefined}
      {children}
    </div>
  ) : (
    ""
  );
};

export const _ModalHeader = ModalHeader;
