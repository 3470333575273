import { z } from "zod";

import { undefinable } from "../../zodHelpers";
import { AlgoliaProductEventValidator, BaseProductEventValidator } from "./shared";

export const ExperimentInitializeEventValidator = z.object({
  /**
   * The quantity number of the product being added to the basket
   *
   * @example 2
   */
  experiment: z.string(),

  /**
   * The query id of the algolia search the lead to the add to basket event
   */
  variant: z.number(),
});

export type IExperimentInitializeEvent = z.infer<typeof ExperimentInitializeEventValidator>;
