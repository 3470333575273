import { startTransition, useEffect, useState } from "react";

function useSSR() {
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    startTransition(() => {
      setIsSSR(false);
    });
  }, []);

  return isSSR;
}

export default useSSR;
