import clsx from "clsx";
import React, { FunctionComponent } from "react";
import AlertError from "svg/alert--error.svg";
import AlertWarning from "svg/alert--warnings.svg";
import AlertCheck from "svg/icon-check-circle.svg";

import { ModalType } from "../Modal";
import styles from "./ModalIcon.style.scss";

interface IModalIcon {
  type: ModalType;
}

const ModalIcon: FunctionComponent<IModalIcon> = ({ type }) => {
  return (
    <div className={clsx(styles.icon, styles[type])}>
      {type === "warning" ? <AlertWarning /> : type === "success" ? <AlertCheck /> : <AlertError />}
    </div>
  );
};

export const _ModalIcon = ModalIcon;
