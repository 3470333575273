import { isHTMLElement } from "../../shared/utils/types/isHTMLElement";

(() => {
  const width = document.documentElement.clientWidth;
  if (width > 1024) {
    [...document.querySelectorAll(".step-by-step-image__content__image")].filter(isHTMLElement).forEach((x) =>
      x.addEventListener("click", (event) => {
        const target = event.target;
        if (!isHTMLElement(target)) {
          return;
        }
        const targetContent = target.closest(".step-by-step-image__content");
        if (isHTMLElement(targetContent)) {
          targetContent.classList.toggle("is-fullsize");
        }
      }),
    );
  }
})();
