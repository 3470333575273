import { z } from "zod";

import { undefinable } from "../../utils/zodHelpers";
import { ResourceValidator } from "./Resource.dto";

export const ArticleContentValidator = z.object({
  name: z.string(),
  absolute_url: z.string(),
  tags: undefinable(z.array(z.object({ name: z.string(), id: z.number() }))),
  article_type: undefinable(z.string()),
  resource: undefinable(ResourceValidator),
});

export const RelatedArticleContentValidator = z.object({
  articles: z.array(ArticleContentValidator),
  article_overview_url: z.string(),
  section_data: z.object({ header: undefinable(z.string()), description: undefinable(z.string()) }),
});

export type IRelatedArticleContent = z.infer<typeof RelatedArticleContentValidator>;
export type IArticleContent = z.infer<typeof ArticleContentValidator>;
