import { z } from "zod";

import { undefinable } from "../../../shared/utils/zodHelpers";

export const BrandValidator = z.object({
  link: z.string(),
  image: z.string(),
});

export const BrandListValidator = z.array(BrandValidator);
export const BrandContentValidator = z.object({
  partners: BrandListValidator,
  show_as_grid: z.boolean(),
  header: undefinable(z.string()),
  sub_header: undefinable(z.string()),
});

export type IBrand = z.infer<typeof BrandValidator>;
export type IBrandList = z.infer<typeof BrandListValidator>;
export type IBrandContent = z.infer<typeof BrandContentValidator>;
