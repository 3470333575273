import clsx from "clsx";
import React, { FunctionComponent } from "react";

import styles from "./HeaderIcon.style.scss";

interface IHeaderIconProps {
  icon: React.FunctionComponent<React.PropsWithChildren<React.SVGAttributes<SVGElement>>>;
  href: string;
  text: string;
  counter?: number;
  className?: string;
}

export const HeaderIcon: FunctionComponent<IHeaderIconProps> = ({
  icon: IconComponent,
  href,
  text,
  className,
  counter,
}) => {
  return (
    <a className={clsx(styles.container, className)} href={href} aria-label={text}>
      {counter ? <span className={styles.counter}>{counter}</span> : null}
      <IconComponent className={styles.icon} />
      <span className={styles.text}>{text}</span>
    </a>
  );
};
