import React, { useContext } from "react";

interface IPowerStepContext {
  powerStep: (productsIds: string[]) => Promise<void>;
}

const PowerStepContext = React.createContext<IPowerStepContext | undefined>(undefined);

export const PowerStepProvider = PowerStepContext.Provider;

export function usePowerStep() {
  const context = useContext(PowerStepContext);
  if (context === undefined) {
    throw new Error("usePowerStep needs to have PowerStepManager as a parent");
  }
  return context.powerStep;
}
