/* eslint-disable simple-import-sort/imports */

import { retryOrReload, retry } from "./shared/utils/retry";
import { onIntersection } from "./shared/utils/intersection";
import { init as SentryInit } from "./shared/utils/LazySentry";

import "./scripts/polyfills";

SentryInit();

if (SETTINGS.DEBUG) {
  import("./shared/modules/DevTools/DevtoolsInitializer");
}

import "./shared/utils/analytics/analyticsInitializer";

if (SETTINGS.FLAGS.walley && document.querySelector(".js-checkout-walley")) {
  retryOrReload(() => import(/* webpackChunkName: "walley" */ "./checkout/walley/WalleyInitializer"));
}

if (
  document.querySelector(".js-basket") ||
  document.querySelector(".js-checkout") ||
  document.querySelector(".js-receipt")
) {
  retryOrReload(() => import(/* webpackChunkName: "new-checkout" */ "./checkout/CheckoutInitializer"));
}

if (document.querySelector(".js-account-settings")) {
  retryOrReload(() => import(/* webpackChunkName: "account" */ "./account/AccountInitializer"));
}

if (window.location.href.match("\\/account")) {
  retryOrReload(() => import(/* webpackChunkName: "accounts" */ "./scripts/views/accounts"));
}

if (document.querySelector("[data-toggle='countdown']")) {
  retry(() => import(/* webpackChunkName: "cms-countdown" */ "./scripts/views/countdown"));
}

if (
  document.querySelector(
    ".cms-mosaic-container__outer-grid-container__discount-block__text-container__inner-text-container__discount-text",
  )
) {
  retry(() => import(/* webpackChunkName: "cms-countup" */ "./scripts/views/countup"));
}

if (document.querySelector(".js-product-card")) {
  retryOrReload(() => import(/* webpackChunkName: "product-page" */ "./productpage/ProductPageInitializer"));
}

if (document.querySelector(".js-product-list")) {
  retryOrReload(() => import(/* webpackChunkName: "product-list" */ "./product-list/ProductListInitializer"));
}

if (document.querySelector(".js-customer-center")) {
  retryOrReload(() => import(/* webpackChunkName: "customer-center" */ "./customercenter/CustomerCenterInitializer"));
}

if (document.querySelector(".js-productlist-slider")) {
  retryOrReload(
    () => import(/* webpackChunkName: "product-list-slider" */ "./shared/modules/ProductList/ProductListInitializer"),
  );
}

if (document.querySelector(".js-product-reviewer")) {
  retryOrReload(() => import(/* webpackChunkName: "product-reviewer" */ "./product-reviewer/OrderReviewerInitializer"));
}

if (document.querySelector(".js-newest-iu-article")) {
  retryOrReload(() => import(/* webpackChunkName: "iu-newest-article" */ "./cms/iu-article/IuArticleInitializer"));
}

if (document.querySelector(".js-article-overview")) {
  retryOrReload(
    () => import(/* webpackChunkName: "article-overview" */ "./articleoverview/ArticleOverviewInitializer"),
  );
}

if (document.querySelector(".js-contact-page")) {
  retryOrReload(() => import(/* webpackChunkName: "contact-page" */ "./contactpage/ContactPageInitializer"));
}

if (document.querySelector(".js-manufactor-focus")) {
  retryOrReload(
    () => import(/* webpackChunkName: "manufactor-focus" */ "./cms/manufactor-focus/ManufactorFocusInitializer"),
  );
}

if (document.querySelector(".js-campaign-overview")) {
  retryOrReload(
    () => import(/* webpackChunkName: "campaign-overview" */ "./cms/campaign-overview/CampaignOverviewInitializer"),
  );
}

onIntersection(document.querySelector(".js-review-slider"), () =>
  retryOrReload(
    () => import(/* webpackChunkName: "review-slider" */ "./shared/modules/ReviewSlider/ReviewSliderInitializer"),
  ),
);

import "./scripts/cms/video";
// it for anything, so it's temporarily disabled
import "./header/HeaderInitializer";
import "./scripts/views/footer";
import "./scripts/views/breadcrumb";
import "./scripts/views/productgrouplist";
import "./scripts/views/price_enquiry";
import "./scripts/views/step-by-step-image";
import "./scripts/views/modal";
import "./scripts/views/newsletter";
import "./scripts/views/topbanner";
import "./scripts/views/analytics";
import "./shared/modules/BrandsSlider/BrandsSliderInitializer";
import "./shared/modules/RelatedArticleSlider/RelatedArticleSliderInitializer";
import "./shared/modules/TopBanners/TopBannersInitializer";
import "./shared/modules/ProductBox/ProductBoxInitializer";
import "./scripts/browsers";
