import { z } from "zod";

import { OpeningTimeValidator } from "../../../contactpage/dto/OpeningTime.dto";
import { undefinable } from "../../../shared/utils/zodHelpers";

export const ShowroomLocations = z.union([
  z.literal("Albertslund"),
  z.literal("Gentofte"),
  z.literal("Maribo"),
  z.literal("Ishøj"),
]);
const ShowroomValidator = z.object({
  opening_hours: OpeningTimeValidator,
  showroom_info: z.object({
    name: z.string(),
    img: z.string(),
    address: z.string(),
    zipcity: z.string(),
    phone: z.string(),
    email: z.string(),
    url: undefinable(z.string()),
    label: undefinable(z.string()),
  }),
});

export const ShowroomsValidator = z.record(ShowroomLocations, ShowroomValidator);

export type IShowrooms = z.infer<typeof ShowroomsValidator>;
export type IShowroom = z.infer<typeof ShowroomValidator>;
export type IShowroomLocations = z.infer<typeof ShowroomLocations>;
