import { Analytics } from "../AnalyticsEvents";

export const initializeCriteo = () => {
  if (!window.CRITEO_ACCOUNT) {
    return;
  }

  Analytics.event("impression", "product").addBatchEventListener((event) => {
    const validEvents = event.filter((e) => e.list === "Product List");
    if (validEvents.length === 0) {
      return;
    }
    const ctots =
      document.location.origin &&
      document.referrer.slice(0, document.location.origin.length) !== document.location.origin
        ? Date.now().toString().slice(0, 10)
        : "";
    window.criteo_q = window.criteo_q || [];
    const ids = validEvents.map((e) => e.id).slice(0, 3);
    window.criteo_q.push(
      { event: "setAccount", account: window.CRITEO_ACCOUNT },
      { event: "setCustomerId", id: window.SESSION_ID },
      { event: "setSiteType", type: "d" },
      { event: "viewList", ctots: ctots, item: ids },
    );
  });
};
