import { isHTMLElement } from "../../shared/utils/types/isHTMLElement";

(() => {
  const footers = document.querySelectorAll(".footer__list-section");

  footers.forEach((footer) => {
    if (!isHTMLElement(footer)) {
      return;
    }

    footer.addEventListener("click", (event) => {
      footer.classList.toggle("active");
    });
  });
})();
