import { isHTMLElement } from "../../shared/utils/types/isHTMLElement";

(() => {
  const productlistgroupContainer = document.querySelectorAll(".js-productlistgroup-container");

  productlistgroupContainer.forEach((productgroup) => {
    if (!isHTMLElement(productgroup)) {
      return;
    }
    const product_group_list = productgroup.querySelector(".js-productlistgroup");
    const showMore = productgroup.querySelector(".js-productlistgroup-show-more");
    if (!isHTMLElement(product_group_list) || !isHTMLElement(showMore)) {
      return;
    }

    if (product_group_list.clientHeight < product_group_list.scrollHeight) {
      showMore.style.visibility = "visible";
    }

    showMore.addEventListener("click", () => {
      product_group_list.style.maxHeight = "100%";
      showMore.style.display = "none";
    });
  });
})();
