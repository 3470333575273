import { HydrationBoundary, QueryClientProvider } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import React, { PropsWithChildren } from "react";
import { I18nProvider } from "react-aria";
import type { HydrationOptions, RootOptions } from "react-dom/client";
import * as ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import { BrowserConfigurationManager } from "../modules/Configuration/BrowserConfigurationManager";
import { localStoragePersistor, queryClient } from "./queryClient";
import { prerenderData } from "./queryHelpers";

const getReactIdentifier = (domElement: Element): string | undefined => {
  return domElement.getAttribute("data-react-id") ?? undefined;
};

const DefaultContext: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <HelmetProvider>
      <I18nProvider locale={SETTINGS.LANGUAGE_CODE}>
        <BrowserConfigurationManager>
          <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: localStoragePersistor }}>
            <HydrationBoundary state={prerenderData()}>{children}</HydrationBoundary>
          </PersistQueryClientProvider>
        </BrowserConfigurationManager>
      </I18nProvider>
    </HelmetProvider>
  );
};

export const hydrateReact = (
  domElement: Element,
  node: React.ReactNode,
  options?: HydrationOptions | RootOptions,
): ReactDOM.Root => {
  let root;
  if (domElement.childElementCount === 0) {
    console.error("Hydration failed, no child elements found in domElement");
    root = renderReact(domElement, node, options);
  } else {
    root = ReactDOM.hydrateRoot(
      domElement,
      // <React.StrictMode>
      <DefaultContext>{node}</DefaultContext>,
      // </React.StrictMode>,
      {
        // eslint-disable-next-line no-console
        // onRecoverableError: console.warn,
        identifierPrefix: getReactIdentifier(domElement),
        ...options,
      },
    );
  }
  return root;
};

export const renderReact = (domElement: Element, node: React.ReactNode, options?: RootOptions): ReactDOM.Root => {
  const identifier = getReactIdentifier(domElement);
  const root = ReactDOM.createRoot(domElement, {
    // eslint-disable-next-line no-console
    // onRecoverableError: console.warn,
    identifierPrefix: identifier,
    ...options,
  });
  root.render(
    // <React.StrictMode>
    <DefaultContext>{node}</DefaultContext>,
    // </React.StrictMode>,
  );
  return root;
};
