import clsx from "clsx";
import React, { FunctionComponent } from "react";

import styles from "./ModalContent.style.scss";

interface IModalContent extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const ModalContent: FunctionComponent<React.PropsWithChildren<IModalContent>> = ({ children, className, ...props }) => {
  const isText = typeof children === "string";
  const ContentTag = isText ? "p" : "div";

  return (
    <ContentTag className={clsx(className, styles.content)} {...props}>
      {children}
    </ContentTag>
  );
};

export const _ModalContent = ModalContent;
