import { useRef, useState } from "react";

import { useIsomorphicLayoutEffect } from "../shared/hooks/useIsomorphicLayoutEffect";

export function useIsScrolled(options?: { threshold?: number; onlyDownward?: boolean }): boolean {
  const [isScrolled, setIsScrolled] = useState(false);
  const lastScroll = useRef(0);

  useIsomorphicLayoutEffect(() => {
    const onScroll = () => {
      const scrollingDown = window.scrollY - lastScroll.current > 0;
      lastScroll.current = window.scrollY;

      setIsScrolled(
        window.scrollY > (options?.threshold ?? 20) && !((options?.onlyDownward ?? false) && !scrollingDown),
      );
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [options?.threshold, options?.onlyDownward]);

  return isScrolled;
}
