import { ConfigurationValidator, IConfiguration } from "../../http/dto/Configuration.dto";
import * as Sentry from "../../utils/LazySentry";

let _cachedConfiguration: IConfiguration;

/**
 * Access the configuration on the window object
 *
 * @remarks
 * consider using {@link shared.modules.Configuration.ConfigurationContext.useConfiguration }
 * in a React context, as it is SSR safe
 */
export const configuration = (): IConfiguration => {
  if (_cachedConfiguration) {
    return _cachedConfiguration;
  }

  let conf: IConfiguration;

  try {
    conf = ConfigurationValidator.parse(window.CONFIGURATION);
  } catch (error) {
    if (!SETTINGS.DEBUG) {
      // Looks like window.CONFIGURATION is FUBAR, but to avoid a catastrophic
      // errors across all usages of configuration, we just cast it to
      // IConfiguration and pray
      Sentry.captureException(error);
      console.error(error);
      conf = window.CONFIGURATION as IConfiguration;
    } else {
      // We want to react to the errors when debugging
      throw error;
    }
  }
  _cachedConfiguration = conf;
  return conf;
};
