import { isHTMLElement, isHTMLInputElement } from "../../shared/utils/types/isHTMLElement";
import { subscribe } from "../modules/newsletter";

/* General handling of subscribe to mailing list, based on classes. */
(() => {
  document.querySelectorAll(".js-newsletter-subscribe-form,.js-newsletter-unsubscribe-form")?.forEach((form) => {
    form.addEventListener("submit", (event) => {
      event.preventDefault();

      const form = event.currentTarget;

      if (!isHTMLElement(form)) {
        return;
      }

      const getValue = (selector: string) => {
        const element = form.querySelector(selector);

        if (!isHTMLInputElement(element)) {
          return "";
        }

        return element.value ?? "";
      };

      const name = getValue("input.name");
      const email = getValue("input.email");
      const source = getValue("input.source");
      const phone = getValue("input.phone");

      const method = form.classList.contains("js-newsletter-subscribe-form") ? "subscribe" : "unsubscribe";

      subscribe(name, email, source, phone, method, (data) => {
        if (form.dataset["redirectOnSubscribe"]) {
          location.replace(form.dataset["redirectOnSubscribe"] as string);
        } else if (form.dataset["redirectOnUnsubscribe"]) {
          location.replace(form.dataset["redirectOnUnsubscribe"] as string);
        } else {
          alert(data.message);
        }
      });
    });
  });
})();
