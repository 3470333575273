import clsx from "clsx";
import React, { FunctionComponent, useMemo, useState } from "react";

import { Container } from "../../shared/components/Container";
import useDebounce from "../../shared/hooks/useDebounce";
import { useConfiguration } from "../../shared/modules/Configuration/ConfigurationContext";
import { IHeaderEntry } from "../dto/Header.dto";
import { useIsScrolled } from "../useIsScrolled";
import styles from "./MegaMenu.style.scss";

interface IMegaMenuProps {
  entries: IHeaderEntry[];
}

export const SubEntry: FunctionComponent<{ entry: IHeaderEntry }> = ({ entry }) => {
  const columns = entry.span_columns ?? 1;
  const rows = Math.ceil(entry.children.length / columns);

  return (
    <div className={styles.subEntry} style={{ gridColumn: `span ${columns}` }}>
      <a href={entry.link} className={styles.subHeader} data-a-name={entry.name} data-a-id={entry.id}>
        {entry.name}
      </a>
      <div
        className={styles.links}
        style={{ gridTemplateColumns: `repeat(${columns}, 1fr)`, gridTemplateRows: `repeat(${rows}, auto)` }}
      >
        {entry.children.map((child) => {
          return (
            <a className={styles.link} key={child.id} href={child.link} data-a-name={entry.name} data-a-id={entry.id}>
              {child.name}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export const MegaMenu: FunctionComponent<IMegaMenuProps> = ({ entries }) => {
  const [activeEntryId, setActiveEntryId] = useState<number>();

  const isScrolling = useIsScrolled({ threshold: 100, onlyDownward: true });

  const [setEntry, reset] = useDebounce((entry: number) => {
    setActiveEntryId(entry);
  }, 100);

  const unsetEntry = () => {
    setActiveEntryId(undefined);
    reset();
  };

  const activeEntry = useMemo(() => {
    return entries.find((entry) => entry.id === activeEntryId && entry.children.length > 0);
  }, [activeEntryId, entries]);

  const collapsed = isScrolling && activeEntry === undefined;
  const active = activeEntry !== undefined;

  return (
    <nav
      className={clsx(styles.nav, collapsed && styles.hidden, active && styles.show)}
      onMouseLeave={unsetEntry}
      data-a-type={"megamenu"}
      id={"megamenu"}
    >
      <div className={styles.overlay} />
      <Container className={styles.wrapper}>
        {entries.map((entry) => {
          if (entry.link === "divider") {
            return <div key={entry.id} className={styles.spacer} onMouseEnter={unsetEntry} />;
          }
          return (
            <a
              className={clsx(
                styles.entry,
                activeEntry?.id === entry.id && styles.active,
                entry.children.length === 0 && styles.noChildren,
              )}
              key={entry.id}
              href={entry.link}
              data-a-name={entry.name}
              data-a-id={entry.id}
              onMouseEnter={() => !collapsed && setEntry(entry.id)}
            >
              {entry.name}
            </a>
          );
        })}
        {activeEntry && activeEntry.children.length > 0 && (
          <div className={styles.dropdown}>
            <div className={styles.dropdownWrapper}>
              {activeEntry.children.map((child) => (
                <SubEntry key={child.id} entry={child} />
              ))}
            </div>
          </div>
        )}
      </Container>
    </nav>
  );
};
