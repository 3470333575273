/**
 * Checks if client device is running iOS.
 *
 * @returns Boolean indicating whether the device is running iOS (or simulates one).
 */
export function isiOSDevice() {
  return (
    ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}
