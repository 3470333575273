import React, { Dispatch, SetStateAction, useContext } from "react";

export interface IAdminHotkey {
  [key: string]: (event: KeyboardEvent) => void;
}

export interface IAdminMenuElements {
  [key: string]: React.ReactChild;
}

export interface IAdminProps {
  Admin: {
    setMenuElements: Dispatch<SetStateAction<IAdminMenuElements>>;
    setHotkeys: Dispatch<SetStateAction<IAdminHotkey>>;
    isAdmin: boolean;
    isAoInternal: boolean;
  };
}

const AdminContext = React.createContext<IAdminProps | undefined>(undefined);

export const AdminProvider = AdminContext.Provider;

export function useAdmin() {
  const context = useContext(AdminContext);
  if (context === undefined) {
    return {
      setMenuElements: () => {
        console.error("Could not register menu element, missing admin context");
      },
      setHotkeys: () => {
        console.error("Could not register hotkey, missing admin context");
      },
      isAdmin: false,
      isAoInternal: false,
    };
  }
  return context.Admin;
}
