import React, { useContext } from "react";

import { IConfiguration } from "../../http/dto/Configuration.dto";

export interface IConfigurationContext {
  configuration: IConfiguration;
}

const ConfigurationContext = React.createContext<IConfigurationContext | undefined>(undefined);

export const ConfigurationProvider = ConfigurationContext.Provider;

export function withConfiguration<P extends IConfigurationContext>(
  Component: React.ComponentType<React.PropsWithChildren<P>>,
) {
  return function BoundComponent(props: Pick<P, Exclude<keyof P, keyof IConfigurationContext>>) {
    return (
      <ConfigurationContext.Consumer>
        {(value) => {
          if (value === undefined) {
            throw new Error(
              "withConfiguration needs to have BrowserConfigurationManager og SsrConfigurationManager as a parent",
            );
          }
          return <Component {...(props as P)} configuration={value.configuration} />;
        }}
      </ConfigurationContext.Consumer>
    );
  };
}

export function useConfiguration() {
  const context = useContext(ConfigurationContext);
  if (context === undefined) {
    throw new Error(
      "useConfiguration needs to have either BrowserConfigurationManager og SsrConfigurationManager as a parent",
    );
  }
  return context.configuration;
}
