import { formData, HTTPError, request } from "../../shared/http/HTTP";

/* Subscribes a given user with a name and email to the current mailing list,
   calls the callback after the backend returns. */
export function subscribe(
  name: string,
  email: string,
  source: string,
  phone: string,
  method: string,
  callback?: (data: { [key: string]: string }) => void,
) {
  request(
    "/nyhedsbrev/rpc/",
    {
      method: "post",
      body: formData({
        email,
        method,
        name,
        source,
        phone,
      }),
    },
    { defaultHeaders: false },
  )
    .then(async (resp) => {
      callback?.(await resp.json());
    })
    .catch(async (error) => {
      if (error instanceof HTTPError) {
        const resp = await error.response.json();
        alert(resp.error);
      }
    });
}
