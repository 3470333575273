/* eslint-disable no-restricted-syntax */
import { z } from "zod";

export const NavigationEventValidator = z.object({
  source_url: z.string(),
  source_type: z.string().optional(),
  source_name: z.string().optional(),
  source_id: z.coerce.string().optional(),
  destination_url: z.string(),
  destination_type: z.string().optional(),
  destination_name: z.string().optional(),
  destination_id: z.coerce.string().optional(),
  session_time: z.number(),
});

export type INavigationEvent = z.infer<typeof NavigationEventValidator>;
