import { fetchCmsSection } from "../../shared/http/Cms.http";
import { isHTMLElement } from "../../shared/utils/types/isHTMLElement";

(() => {
  [...document.querySelectorAll(".js-open-modal[data-section]")].filter(isHTMLElement).forEach((element) => {
    element.addEventListener("click", async (event) => {
      if (!element.dataset.section) {
        return;
      }
      event.preventDefault();
      const resp = await fetchCmsSection(element.dataset.section);
      const { showModal } = await import("../../shared/utils/showModal");
      showModal({ ...resp, size: "fullscreen" });
    });
  });
})();
