import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { removeOldestQuery } from "@tanstack/react-query-persist-client";

import { HTTPError } from "../http/HTTP";

const NON_RETRIABLE_STATUS_CODES = [401, 403, 404, 422];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: 1000 * 60 * 15, // Keep stale items for 15 minutes
      staleTime: 5 * 1000, // Cache items for 5 seconds
      retry: (failureCount, error) => {
        if (error instanceof HTTPError && NON_RETRIABLE_STATUS_CODES.includes(error.response.status)) {
          return false;
        }
        return failureCount < 3;
      },
    },
  },
});

export const localStoragePersistor = createSyncStoragePersister({
  storage: global.window?.localStorage,
  retry: removeOldestQuery,
});
